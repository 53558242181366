export default {
  "integration-item": "cQy",
  "icon-container": "cQU",
  "content": "cQj",
  "title-container": "cQW",
  "new-badge": "cQu",
  "title": "ceS",
  "description": "cec",
  "no-attributes": "ceq",
  "connect-cta": "ceZ"
};

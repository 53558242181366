export default {
  "header-cell": "cIR",
  "empty": "cIQ",
  "header-content": "cIe caption-bold",
  "active": "cIB",
  "align-right": "cIE",
  "first-col": "cId",
  "mid-col": "cII",
  "status-col": "cIt"
};

export default {
  "sidebar": "cdi",
  "top-section": "cdA",
  "title": "cdY",
  "primary-action": "cdh",
  "edit-icon": "cdf",
  "close-icon": "cdK",
  "box-wrapper": "cdG",
  "bottom-action": "cdr",
  "related-invoices": "cdb",
  "related-invoices-title": "cdy mb-16 caption-bold",
  "box": "cdU",
  "box-element": "cdj",
  "cancel-text": "cdW",
  "box-header": "cdu",
  "row": "cIS",
  "greyed-out": "cIc",
  "struck-through": "cIq",
  "share-mandate-link": "cIZ"
};

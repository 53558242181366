export default {
  "mandate-list-item": "ctw",
  "selected": "ctN",
  "status-avatar": "ctv",
  "infos": "cto",
  "info-title": "ctn body-1",
  "info-title-suspended": "cti",
  "info-title-status-suspended": "ctA",
  "info-mandate-count": "ctY body-2"
};

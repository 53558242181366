export default {
  "company-logo": "cPW",
  "list-item": "cPu",
  "link": "clS",
  "title-wrapper": "clc",
  "title": "clq body-2",
  "subtitle": "clZ caption",
  "truncated-text": "clR",
  "image-placeholder": "clQ"
};

export default {
  "wrapper": "ceo",
  "content": "cen",
  "back": "cei btn btn--tertiary",
  "heading": "ceA",
  "heading-content": "ceY",
  "logo": "ceh",
  "description": "cef body-2",
  "cta": "ceK"
};

export default {
  "fullscreen-modal": "cDH",
  "creation-form-modal": "cDO",
  "links-container": "cDm",
  "link-field": "cDp",
  "link": "cDx",
  "add-link": "cDw",
  "remove-link": "cDN",
  "flex-row": "cDv",
  "flex-item": "cDo",
  "unit-trigger": "cDn",
  "dynamic-placeholders": "cDi"
};

export default {
  "item": "cMm",
  "selected": "cMp",
  "hover-revised": "cMx",
  "asset-container": "cMw",
  "asset": "cMN",
  "revoked-icon": "cMv",
  "pending-icon": "cMo",
  "revoked": "cMn",
  "revised-revoke": "cMi",
  "pending": "cMA",
  "revised-pending": "cMY",
  "invitable": "cMh",
  "info": "cMf",
  "name": "cMK",
  "email": "cMG",
  "actions": "cMr",
  "invitable-member-delete": "cMb",
  "invitable-member-invite": "cMy"
};

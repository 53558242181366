export default {
  "name-wrapper": "cgR",
  "preset-name": "cgQ",
  "invoices-entry-period-container": "cge",
  "transactions-entry-period-container": "cgB",
  "period-wrapper": "cgE",
  "period-btn": "cgd",
  "cancel-placeholder": "cgI",
  "period-cancel-btn": "cgt",
  "transactions-entry-container": "cgM",
  "invoices-entry-container": "cgP",
  "dropdown": "cgl",
  "dropdown-opened": "cgX",
  "dropdown-closed": "cgC"
};

export default {
  "section-item": "cke",
  "sm": "ckB",
  "xs": "ckE",
  "section-item-label": "ckd body-2",
  "section-item-value": "ckI body-2",
  "positive": "ckt",
  "negative": "ckM",
  "hidden": "ckP",
  "section-item-caption": "ckl caption"
};

export default {
  "donut-chart-container": "cCQ",
  "donut-chart-wrapper": "cCe",
  "chart": "cCB",
  "donut-chart-legend-container": "cCE caption",
  "donut-chart-legend-item-empty": "cCd",
  "donut-chart-legend-bullet-empty": "cCI",
  "donut-chart-legend-bullet": "cCt",
  "donut-chart-legend-text-empty": "cCM",
  "donut-chart-legend-text": "cCP",
  "donut-chart-custom-label-uncategorized": "cCl",
  "custom-label-uncategorized-button": "cCX body-1",
  "donut-chart-legend": "cCC",
  "donut-chart-legend-item": "cCk",
  "donut-chart-legend-other-item": "cCJ",
  "donut-chart-legend-uncategorized-item": "cCs",
  "donut-chart-legend-text-name": "cCF",
  "tooltip": "cCg"
};

export default {
  "container": "cIz",
  "mapped": "cIa",
  "header": "cIH",
  "title": "cIO caption",
  "select-option-label": "cIm",
  "content": "cIp",
  "item": "cIx body-2",
  "empty": "cIw"
};

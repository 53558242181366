export default {
  "custom-period-wrapper": "cCU",
  "fadeIn": "cCj",
  "custom-period-description": "cCW body-2",
  "header": "cCu",
  "arrow-left": "ckS",
  "body": "ckc",
  "period-wrapper": "ckq",
  "back-button": "ckZ",
  "footer": "ckR"
};

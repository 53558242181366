export default {
  "sidebar-section": "ceG body-2",
  "sidebar-section-header": "cer body-1 mb-4",
  "providers-logos": "ceb",
  "risks": "cey",
  "tag": "ceU tag",
  "external-link-icon": "cej",
  "disclaimer": "ceW",
  "divider": "ceu"
};

export default {
  "members-loading": "cPX",
  "members-loading--ff": "cPC",
  "header": "cPk",
  "header-search-bar": "cPJ",
  "body": "cPs",
  "members": "cPF",
  "placeholder-container": "cPg",
  "icon": "cPD",
  "details": "cPV",
  "member-loading": "cPL",
  "member-loading--no-ff": "cPT",
  "body-members": "cPz",
  "body-details": "cPa"
};

export default {
  "container": "cBd",
  "header": "cBI",
  "content": "cBt",
  "info-list": "cBM",
  "info-label": "cBP body-2",
  "info-value": "cBl body-2",
  "provider": "cBX",
  "policy-container": "cBC",
  "policy-label": "cBk body-2",
  "policy-number": "cBJ body-2",
  "copy-to-clipboard": "cBs",
  "button": "cBF btn btn--primary btn--stretch btn--large"
};

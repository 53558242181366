export default {
  "row": "clj",
  "terminated": "clW",
  "status-avatar": "clu",
  "amount": "cXS body-1",
  "item-info": "cXc",
  "date-cell": "cXq",
  "cell": "cXZ body-2",
  "status-icon": "cXR",
  "amount-cell": "cXQ",
  "item-name-cell": "cXe",
  "item-name-container": "cXB"
};

export default {
  "member-details": "ctf",
  "header": "ctK",
  "no-email": "ctG",
  "header-img": "ctr",
  "invited-persona": "ctb",
  "revoked-persona": "cty",
  "invited-icon": "ctU",
  "revoked-icon": "ctj",
  "revised": "ctW",
  "invitable-icon": "ctu",
  "dropdown": "cMS",
  "header-body": "cMc",
  "status": "cMq",
  "name": "cMZ",
  "email": "cMR caption-bold",
  "actions": "cMQ body-2",
  "actions-cards": "cMe",
  "actions-transactions": "cMB",
  "body": "cME",
  "expense-permissions-section": "cMd",
  "body-title": "cMI title-4",
  "body-attr": "cMt",
  "body-label": "cMM",
  "attr-buttons": "cMP",
  "body-role": "cMl small",
  "details-actions": "cMX",
  "actions-item": "cMC",
  "disclaimer": "cMk",
  "permissions": "cMJ",
  "permission": "cMs",
  "permission-label": "cMF",
  "permission-check": "cMg",
  "permission-missing": "cMD",
  "editable-section": "cMV",
  "hris-placeholder": "cML",
  "imported-from": "cMT",
  "integration-logo": "cMz",
  "permission-value": "cMa",
  "tooltip": "cMH",
  "check-icon": "cMO"
};

export default {
  "fieldset": "cEv",
  "numbering-format": "cEo",
  "numbering-fields": "cEn",
  "input-settings": "cEi",
  "label-tooltip": "cEA",
  "tooltip-icon": "cEY",
  "next-invoice-number": "cEh",
  "spacing": "cEf"
};

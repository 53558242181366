export default {
  "wrapper": "csJ",
  "header": "css",
  "fadeIn": "csF",
  "header-title": "csg title-4",
  "transactions-container": "csD",
  "placeholder-title": "csV",
  "transaction-item-list-container": "csL",
  "transaction-item-list": "csT",
  "item": "csz"
};

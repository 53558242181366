export default {
  "wrapper": "cJo",
  "header": "cJn",
  "fadeIn": "cJi",
  "header-content": "cJA",
  "with-cta": "cJY",
  "header-title": "cJh title-4",
  "advanced-filters-trigger": "cJf",
  "filters-button": "cJK",
  "filters-button-upsell": "cJG",
  "filters-upsell-item": "cJr",
  "filters-badge": "cJb",
  "chart-container": "cJy",
  "chart-legend-container": "cJU mb-24",
  "chart-watermark-container": "cJj",
  "chart-watermark": "cJW",
  "placeholder-title": "cJu",
  "placeholder-subtitle": "csS",
  "advanced-filters": "csc"
};

export default {
  "header": "cdm",
  "center": "cdp",
  "header-placeholder": "cdx",
  "placeholder": "cdw",
  "name-block": "cdN",
  "block": "cdv",
  "detail-block": "cdo",
  "border-top": "cdn"
};

export default {
  "card": "ctc",
  "focus": "ctq",
  "title-format": "ctZ",
  "header": "ctR",
  "headings": "ctQ",
  "title": "cte title-4",
  "main": "ctB title-3",
  "footer": "ctE"
};

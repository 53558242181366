export default {
  "file-uploader": "cVp",
  "file-uploader-content": "cVx",
  "file-uploader-dropzone": "cVw",
  "fixedSize": "cVN",
  "two-column": "cVv",
  "file-uploader-label": "cVo body-1",
  "required-chip": "cVn",
  "animated-container": "cVi",
  "toggle-button": "cVA",
  "toggle-button-icon": "cVY"
};

export default {
  "row": "cVM",
  "cell": "cVP",
  "title": "cVl",
  "internal-note": "cVX",
  "unit": "cVC",
  "price": "cVk",
  "quick-actions": "cVJ",
  "missing-details-container": "cVs",
  "missing-details-label": "cVF body-2",
  "warning-icon": "cVg",
  "empty": "cVD",
  "checkbox": "cVV",
  "align-checkbox": "cVL"
};

export default {
  "wrapper": "cJI",
  "cashflow-status": "cJt",
  "status": "cJM",
  "estimated": "cJP",
  "vat-due-amount": "cJl title-3",
  "vat-tooltip": "cJX",
  "icon-info": "cJC",
  "charts-wrapper": "cJk",
  "chart-error": "cJJ",
  "chart-controls": "cJs",
  "amount-loading": "cJF"
};

export default {
  "y-axis-placeholder": "cXK",
  "y-axis-navigable": "cXG",
  "y-axis-tick": "cXr",
  "x-axis-placeholder": "cXb",
  "x-axis-navigable": "cXy",
  "x-axis-tick-wrapper": "cXU",
  "x-axis-tick": "cXj",
  "chart-loading-renderer": "cXW"
};

export default {
  "header-cell": "cVS",
  "checkbox-cell": "cVc",
  "title-col": "cVq",
  "internal-note-col": "cVZ",
  "unit-col": "cVR",
  "price-col": "cVQ",
  "header-content": "cVe caption-bold",
  "active": "cVB",
  "empty": "cVE",
  "align-checkbox": "cVd"
};

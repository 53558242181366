export default {
  "row": "cFY",
  "cell": "cFh body-1",
  "row-cancelled": "cFf",
  "cell-amount": "cFK",
  "empty": "cFG",
  "align-right": "cFr",
  "debitor-container": "cFb",
  "debitor-info": "cFy",
  "debitor-name": "cFU body-1",
  "with-sidebar": "cFj",
  "method-col": "cFW",
  "resource-number": "cFu caption",
  "resource-icon": "cgS",
  "avatar": "cgc"
};

export default {
  "invitations-password-form": "cEP",
  "mobile-mode": "cEl",
  "logo": "cEX",
  "header": "cEC",
  "controls": "cEk",
  "footer": "cEJ",
  "submit-cta": "cEs",
  "subtitle": "cEF"
};

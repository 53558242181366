export default {
  "details-box": "clY",
  "picto": "clh",
  "picto-status": "clf",
  "ghost": "clK",
  "primary-info": "clG",
  "date": "clr",
  "account-infobox": "clb",
  "declined-disclaimer": "cly",
  "nrc-download": "clU"
};

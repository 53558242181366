export default {
  "notifications-settings": "clE",
  "opened": "cld",
  "icon": "clI",
  "header": "clt",
  "header-toggle": "clM",
  "avatar-image": "clP",
  "title": "cll",
  "icon--right": "clX",
  "toggle-container": "clC",
  "toggle": "clk"
};

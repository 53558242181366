export default {
  "wrapper": "cBm",
  "mobile-mode": "cBp",
  "logo": "cBx",
  "address-form": "cBw",
  "button-container": "cBN",
  "address-form-header": "cBv",
  "address-form-content": "cBo",
  "address-form-address-search": "cBn",
  "ember-basic-dropdown": "cBi",
  "address-form-footer": "cBA",
  "submit-cta": "cBY",
  "form": "cBh",
  "city-section": "cBf",
  "title": "cBK",
  "mobile-title": "cBG"
};

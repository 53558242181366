export default {
  "bullet": "ckV",
  "slice-0": "ckL",
  "slice-1": "ckT",
  "slice-2": "ckz",
  "slice-3": "cka",
  "slice-4": "ckH",
  "slice-5": "ckO",
  "slice-6": "ckm",
  "slice-other": "ckp",
  "slice-uncategorized": "ckx",
  "label": "ckw",
  "ellipsis": "ckN",
  "rest": "ckv",
  "previous-date-range": "cko caption"
};

export default {
  "header": "cFT",
  "top-section": "cFz",
  "close": "cFa btn btn--icon-only btn--tertiary",
  "container": "cFH",
  "picto": "cFO",
  "details": "cFm",
  "expiration-date": "cFp",
  "payment-method": "cFx"
};

export default {
  "container": "cdE",
  "form-container": "cdd",
  "title": "cdI",
  "title-border": "cdt",
  "tabs-container": "cdM",
  "preview-container": "cdP",
  "email-preview-container": "cdl",
  "close-button": "cdX btn btn--icon-only btn--tertiary btn--large"
};

export default {
  "item-status": "csq",
  "item-date": "csZ",
  "item-amount": "csR body-1",
  "item-amount-credit": "csQ",
  "row": "cse",
  "icon": "csB",
  "disabled": "csE",
  "icon-container": "csd",
  "text-container": "csI",
  "text-row": "cst",
  "item-details": "csM",
  "counterparty-name": "csP",
  "column": "csl",
  "item-amount-disabled": "csX",
  "attachment-tooltip": "csC",
  "bank-account-avatar": "csk"
};

export default {
  "widgets-container": "cJc",
  "subtitle-container": "cJq",
  "indicator-container": "cJZ",
  "transactions-container": "cJR",
  "indicator": "cJQ",
  "cashflow-container": "cJe",
  "cashflow": "cJB",
  "donut-container": "cJE",
  "donut": "cJd"
};

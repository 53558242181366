export default {
  "card": "cXF",
  "header": "cXg",
  "avatar": "cXD",
  "title": "cXV",
  "subtitle": "cXL",
  "label": "cXT",
  "item": "cXz body-2",
  "header-icon": "cXa",
  "dropdown-menu": "cXH",
  "menu-item": "cXO body-2",
  "delete": "cXm"
};

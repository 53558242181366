export default {
  "body": "cMU",
  "members": "cMj",
  "members-results": "cMW",
  "members-list": "cMu",
  "members-title": "cPS caption-bold",
  "member": "cPc",
  "empty-illustration": "cPq",
  "empty-title": "cPZ title-3",
  "empty-description": "cPR body-2",
  "invitable-members": "cPQ",
  "details": "cPe",
  "pagination-footer": "cPB"
};

export default {
  "option": "cgi",
  "account-description": "cgA",
  "account-identification": "cgY",
  "account-avatar": "cgh",
  "account-name": "cgf body-2",
  "account-balance": "cgK body-2",
  "external-accounts-disclaimer": "cgG",
  "status": "cgr body-2",
  "failed-connection": "cgb"
};

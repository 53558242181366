export default {
  "account-container": "csr",
  "badge": "csb",
  "subtitle": "csy title-4",
  "accounts-filter": "csU",
  "account-filter-label": "csj",
  "status": "csW",
  "account-info": "csu",
  "balance": "cFS",
  "revealed-balance": "cFc",
  "hidden-balance": "cFq",
  "mr-12": "cFZ",
  "disabled-selector": "cFR"
};

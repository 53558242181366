export default {
  "bucket-item": "cQN",
  "button": "cQv",
  "content": "cQo",
  "bucket-icon": "cQn",
  "bucket-title-container": "cQi",
  "bucket-new-badge": "cQA",
  "bucket-title": "cQY",
  "arrow-icon": "cQh"
};

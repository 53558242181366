export default {
  "navigation-dropdown": "cPx",
  "expanded": "cPw",
  "trigger": "cPN",
  "trigger-title-icon": "cPv",
  "collapsed": "cPo",
  "responsive": "cPn",
  "logo": "cPi",
  "active": "cPA",
  "trigger-title": "cPY",
  "content": "cPh",
  "content-container": "cPf",
  "dropdown-icon": "cPK",
  "link": "cPG"
};

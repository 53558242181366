export default {
  "card": "cXp",
  "header": "cXx",
  "avatar": "cXw",
  "title": "cXN",
  "subtitle": "cXv",
  "label": "cXo",
  "item": "cXn body-2",
  "header-icon": "cXi",
  "mt-8": "cXA"
};
